import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import ca from '@/i18n/resources/ca.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ca,
    },
    lng: 'ca',
    fallbackLng: 'ca',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })
