import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Toaster } from 'react-hot-toast'
import { ScrollRestoration } from 'react-router-dom'

import Container from '@/layout/container'
import Historical from '@/components/historical'
import GameHeader from '@/components/games/header'
import { AdsModal, CountDownModal, PauseGameModal } from '@/components/games/modal'
import { StatusEnum, useCurrentGameStore } from '@/store/game'
import { useTimer } from '@/store/timer'
import ARALogo from '@/assets/ara.png'
import Link from '@/components/Link'
import { useGamesStore } from '@/store/games'


interface GameContainer {
  children: React.ReactNode
  type: string
  url: string
}


function GameContainer({
  children,
  type,
  url,
}: GameContainer) {
  const { id = 'last' } = useParams<{
    id: string
  }>()
  const gameStore = useCurrentGameStore()
  const gamesStore = useGamesStore()
  const [loading, setLoading] = useState(true)

  const currentGame = gamesStore.games.find(game => game.gameType === type)

  const refGame = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      if (type && id) {
        await gameStore.fetchGame(id, type, url)
      }
      setLoading(false)
    }

    init()

    return () => {
      useTimer.getState().reset()

      gameStore.reset()
    }

  }, [type, id])

  const onFocus = () => {}

  const onClickOutside = useCallback((e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (refGame.current && !refGame.current.contains(e.target as Node)) {
      onBlur()
    }
  }, [refGame.current])

  const onBlur = useCallback(() => {
    if (gameStore.status === StatusEnum.playing) {
      gameStore.pause()

    }
  }, [gameStore.status])

  const handleActivity: any = (forcedFlag: boolean) => {
    forcedFlag ? onFocus() : onBlur()
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    window.addEventListener('visibilitychange', handleActivity)
    onFocus()

    const onUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      e.returnValue = ''
    }

    if (gameStore.status === StatusEnum.playing) {
      window.addEventListener('beforeunload', onUnload)

    } else {
      window.removeEventListener('beforeunload', onUnload)

    }

    return () => {
      window.removeEventListener('beforeunload', onUnload)
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
      window.removeEventListener('visibilitychange', handleActivity)
    }
  }, [gameStore.status])

  useEffect(() => {
    if (refGame.current) {
      refGame.current.scrollTop = 0

      window.addEventListener('pointerdown', onClickOutside)
    }

    return () => {
      window.removeEventListener('pointerdown', onClickOutside)
    }

  }, [id, refGame.current])

  return (
    <>
      <ScrollRestoration />
      <div className='flex items-stretch justify-center h-16 px-3 space-x-3 border-b border-gray-300'>
        <Link to='/' className='max-w-[62px]'>
          <img src={ARALogo} alt='ARA Logo' className='max-h-full py-3 m-0' />
        </Link>
        <div className='my-3 border-r border-gray-300' />
        <h1 className='flex items-center text-lg font-semibold uppercase'>
          {currentGame?.config?.title}
        </h1>
      </div>
      <Container>
        <Historical />
      </Container>
      <div id='ara-game-container' className='relative'>
        <Container>
          <GameHeader />
          <div ref={refGame} className='pb-20'>
            <Toaster
              containerStyle={{
                position: 'absolute',
              }}
              position='top-right'
            />
            <CountDownModal />
            <PauseGameModal />
            <AdsModal />
            {
              loading ? (
                <div className='flex items-center justify-center h-96'>
                  <div className='flex items-center justify-center space-x-2'>
                    <div className='w-3 h-3 rounded-full bg-primary animate-bounce' />
                    <div className='w-3 h-3 rounded-full bg-primary animate-bounce' />
                    <div className='w-3 h-3 rounded-full bg-primary animate-bounce' />
                  </div>
                </div>
              ) :
              children
            }
          </div>
        </Container>
      </div>
    </>
  )
}

export default GameContainer
