import Cookies from 'js-cookie'
import { create } from 'zustand'


export interface UserDto {
  id: string
  name: string
  email?: string
}


export interface UserState {
  user: UserDto | null
  logged: boolean
  fetchUser: () => Promise<UserDto | null>
}


export const useUser = create <UserState> (
  (set: any, get: any) => ({
    user: null,
    logged: false,
    fetchUser: async () => {
      if (get().user) {
        return get().user
      }

      try {
        const user = JSON.parse(Cookies.get('nx-session-user') || '')

        if (user) {
          set({
            user: {
              name: user.nick,
              email: user.email,
              id: user.userId,
            },
            logged: true,
          })

          return get().user
        }

      } catch(e) {}

      const response = await fetch('https://www.ara.cat/api/front/user/profile')
      const { id, name, ok } = await response.json()

      if (ok) {
        set({
          user: {
            id,
            name,
          },
          logged: true,
        })

      }

      return get().user
    },
  }),
)
