import { ReactElement, useEffect } from 'react'

import { useGamesStore } from '@/store/games'
import { useUser } from '@/store/user'


type Props = {
  children: ReactElement | null
}


export default function Auth({
  children,
}: Props) {
  const userStore = useUser()
  const gamesStore = useGamesStore()

  useEffect(() => {
    userStore.fetchUser()
    gamesStore.fetchGames()
  }, [userStore])

  return children
}
