import { NavLink } from 'react-router-dom'
import classNames from 'classnames'


type Props = {
  onClick?: () => void
  className?: string
  as?: React.ElementType
  children: React.ReactNode
  secondary?: boolean
  disabled?: boolean
  [key: string]: any
}

function Link({
  onClick = () => {},
  className = '',
  as: Component = NavLink,
  secondary = false,
  disabled = false,
  children,
  ...props
}: Props) {
  return (
    <Component
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        {
          'text-primary hover:underline': !secondary && !disabled,
          'text-disabled hover:underline': secondary && !disabled,
          'cursor-not-allowed text-disabled': disabled,
          'active:scale-[.95]': !disabled,
        },
        'transition-all',
        className,
      )}
      {...props}
    >
      {children}
    </Component>
  )
}

export default Link
