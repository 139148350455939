import { create } from 'zustand'
import { useCurrentGameStore } from '@/store/game'



export interface TimerDto {
  time: number
  interval: any
  isActive: boolean
  start: () => void
  stop: () => void
  reset: (time?: number) => void
}


export const useTimer = create <TimerDto> (
  (set: any, get: any) => ({
    time: 0,
    interval: null,
    isActive: false,
    start: () => {
      const interval = setInterval(() => {
        set({ time: get().time + 1 })
        useCurrentGameStore.getState().saveLocal({
          ...useCurrentGameStore.getState().currentGameState,
          time: get().time,
        })
      }, 1000)
      set({ interval, isActive: true })
    },
    stop: () => {
      clearInterval(get().interval)
      set({ interval: null, isActive: false })
    },
    reset: (time: number = 0) => {
      clearInterval(get().interval)
      set({ time, interval: null, isActive: false })
    },
  }),
)
