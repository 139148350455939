import { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Container from '@/layout/container'
import Button from '@/components/button'
import { FinishGameModal, HowPlayModal, InitGameModal } from '@/components/games/modal'
import { StatusEnum, useCurrentGameStore } from '@/store/game'
import MetaHeaders from '@/components/games/meta-headers'
import GameExplainSvg from './game-explain.svg'



export const Board = () => {
  const { currentGame, currentGameState, status, setCurrentGameState, finish } = useCurrentGameStore()
  const { t } = useTranslation()

  const initialBoard: (number | null)[][] | undefined = currentGame?.config?.map(
    (row: number[]) => row.map((col: number) => col === 0 ? null : col)
  )
  const board: (number | null)[][] = currentGameState?.state || initialBoard
  const marginBoard = 2
  const boxSize = 50

  let division: number[] = []
  division = [3, 3]

  const [selected, setSelected] = useState<number[]>([])
  const [withPen, setWithPen] = useState<boolean>(true)
  const [penValues, setPenValues] = useState<(number | null)[][][]>()
  const [selectedNumbers, setSelectedNumbers] = useState<{
    [key: string]: number
  }>({})
  const [isValidating, setValidating] = useState<boolean>(false)

  const onChange = useCallback((value: number | null) => {
    if (status !== StatusEnum.playing) return

    const row = selected[0]
    const col = selected[1]

    setValidating(false)

    if (!withPen) {
      const _penValues = penValues ? [...penValues] : []

      if (value && _penValues[row]?.[col]?.includes(value)) {
        _penValues[row][col] = _penValues[row][col].filter(v => v !== value)
      } else {
        _penValues[row] = _penValues[row] || []
        _penValues[row][col] = _penValues[row][col] || []
        if (!value) {
          _penValues[row][col].pop()

        } else {
          _penValues[row][col].push(value)

        }
      }
      setPenValues(_penValues)
      return
    }

    setTimeout(() => {
      if (isValidated()) {
        finish()
        setSelected([])
      }
    }, 200)

    if (initialBoard?.[row]?.[col] !== null) return
    const _board = [...board]
    _board[row][col] = value
    setCurrentGameState(_board)
  }, [status, board, initialBoard, selected, withPen, penValues])

  const onKeyUp = useCallback((e: any) => {
    if ([
      1, 2, 3, 4, 5, 6, 7, 8, 9
    ].includes(Number(e.key))) {
      onChange(Number(e.key))
    }

    if (e.key === 'Backspace') {
      onChange(null)
    }

  }, [selected, onChange])

  useEffect(() => {
    document.addEventListener('keyup', onKeyUp, false)

    return () => {
      document.removeEventListener('keyup', onKeyUp, false)
    }
  }, [selected, onChange])

  const checkInRow = useCallback((row: number, value: number | null) => {
    if (!value) return false
    const _board = board[row]
    return _board.reduce((acc: number, col: null | number) => {
      if (col && value && col === value) {
        return acc + 1
      }
      return acc
    }, 0) > 1
  }, [board, selected])

  const checkInCol = useCallback((col: number, value: number | null) => {
    if (!value) return false
    const _board = board.map(row => row[col])
    return _board.reduce((acc: number, col: null | number) => {
      if (col && value && col === value) {
        return acc + 1
      }
      return acc
    }, 0) > 1
  }, [board, selected])

  const checkInSquare = useCallback((row: number, col: number, value: number | null) => {
    if (!value) return false

    const squareRow = Math.floor(row / 3)
    const squareCol = Math.floor(col / 3)

    let count = 0

    for (let i = squareRow * division[0]; i < squareRow * division[0] + division[0]; i++) {
      for (let j = squareCol * division[1]; j < squareCol * division[1] + division[1]; j++) {
        if (board[i][j] === value) {
          count += 1
        }
      }
    }

    return count > 1
  }, [board, division])

  const isValidated = useCallback(() => {
    return board.every((row, rowIndex) => {
      return row.every((value, colIndex) => {
        if (!value) return false
        if (!!value && (checkInRow(rowIndex, value) || checkInCol(colIndex, value) || checkInSquare(rowIndex, colIndex, value))) {
          return false
        }
        return true
      })
    })
  }, [board, checkInRow, checkInCol, checkInSquare])

  const hasToFill = useCallback(
    (row: number, col: number) => {
      return (
        (selected[0] === row || selected[1] === col) ||
        (Math.floor(selected[0] / division[0]) === Math.floor(row / division[0]) && Math.floor(selected[1] / division[1]) === Math.floor(col / division[1]))
      )
    }, [selected]
  )

  useEffect(() => {
    const _selectedNumbers: {
      [key: string]: number
    } = {}
    board?.forEach((row, rowIndex) => {
      row.forEach((col, colIndex) => {
        if (col && hasToFill(rowIndex, colIndex)) {
          if (!_selectedNumbers[col]) {
            _selectedNumbers[col] = 0
          }

          _selectedNumbers[col] += 1
        }
      })
    })
    setSelectedNumbers(_selectedNumbers)
  }, [board?.length, hasToFill])

  if (!board?.[0]?.length) return null

  return (
    <>
      <svg
        height='100%'
        className='max-h-[500px] uppercase'
        strokeMiterlimit='10'
        version='1.1'
        viewBox={
          `0 0 ${board.length * boxSize + marginBoard + 1} ${board?.[0].length * boxSize + marginBoard + 1}`
        }
        width='100%'
        xmlSpace='preserve'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >

        {
          board.map((row, rowIndex) => {
            return row.map((value, colIndex) => {
              // const isHighlighted = hasToFill(rowIndex, colIndex)
              const isEditable = initialBoard && initialBoard[rowIndex][colIndex] === null
              const error = checkInCol(colIndex, value) || checkInRow(rowIndex, value) || checkInSquare(rowIndex, colIndex, value)
              const isSelected = selected[0] === rowIndex && selected[1] === colIndex

              return (
                <g
                  key={`${rowIndex}-${colIndex}`}
                  transform={`translate(${colIndex * boxSize + marginBoard}, ${rowIndex * boxSize + marginBoard})`}
                  className={classNames(
                    'group fill-current group cursor-pointer origin-center transition-all',
                  )}
                  onClick={() => {
                    if (initialBoard && initialBoard[rowIndex][colIndex]) return
                    setSelected([rowIndex, colIndex])
                  }}
                >
                  <path
                    d={`M 0 0 L ${boxSize} 0 L ${boxSize} ${boxSize} L 0 ${boxSize} Z`}
                    className={classNames(
                      'fill-current text-transparent stroke-black',
                      {
                        // '!text-[rgba(179,212,242,0.2)]': isHighlighted && !isSelected,
                        '!text-primary-light': initialBoard && initialBoard[rowIndex][colIndex],
                        '!text-primary': isSelected,
                      }
                    )}
                    fillRule='nonzero'
                    opacity='1'
                  />
                  {
                    penValues && penValues[rowIndex] && penValues[rowIndex][colIndex] && (
                      Array.from({ length: board.length }).map((_, index: number) => {
                          let x = index * 20 + 10
                          let y = 10
                          const partboxSize = (boxSize + 2) / 4

                          switch (index) {
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                              x = partboxSize * index + 5
                              y = 10
                              break
                            case 4:
                            case 5:
                            case 6:
                              x = partboxSize * 3 + 5
                              y = partboxSize * (index - 3) + 8
                              break
                            case 7:
                            case 8:
                              x = boxSize - (partboxSize * (index - 5) - 8)
                              y = partboxSize * (6 - 3) + 8
                              break

                            default:
                              break
                          }

                          return (
                            <text
                              key={`${rowIndex}-${colIndex}-${index}`}
                              x={x}
                              y={y}
                              textAnchor='middle'
                              className={classNames(
                                'text-xs',
                                {
                                  'hidden': penValues && penValues[rowIndex] && penValues[rowIndex][colIndex] && penValues[rowIndex][colIndex].indexOf(index + 1) === -1,
                                  'text-white': isSelected,
                                  'text-disabled': !isSelected,
                                }
                              )}
                            >
                              {index + 1}
                            </text>
                          )
                        }
                      )
                    )
                  }
                  <text
                    x={boxSize / 2}
                    y={boxSize / 2}
                    textAnchor='middle'
                    className={classNames(
                      'fill-current transition-all text-3xl',
                      {
                        '!text-danger': isEditable && error && isValidating,
                        '!text-success': isEditable && !error && isValidating,
                        'text-white': isSelected,
                      }
                    )}
                    style={{
                      transformOrigin: 'center center',
                      transformBox: 'fill-box',
                      dominantBaseline: 'central',
                    }}
                  >
                    {value}
                  </text>
                </g>
              )
            })
          })
        }

        {
          (board && board.length > 0) &&
          Array.from({ length: board.length + 1 }).reduce((acc: any, _, index: number) => {
            if (index % division[0] !== 0) return acc

            return (
              [
                ...acc,
                <line
                  key={index}
                  className='stroke-2 stroke-black'
                  x1={marginBoard}
                  y1={index * boxSize + marginBoard}
                  x2={board.length * boxSize + marginBoard}
                  y2={index * boxSize + marginBoard}
                />,
              ]
            )

          }, []) as any
        }
        {
          (board && board[0] && board[0].length > 0) &&
          Array.from({ length: board.length + 1 }).reduce((acc: any, _, index: number) => {
            if (index % division[1] !== 0) return acc

            return (
              [
                ...acc,
                <line
                  key={index}
                  className='stroke-2 stroke-black'
                  x1={index * boxSize + marginBoard}
                  y1={marginBoard}
                  x2={index * boxSize + marginBoard}
                  y2={board.length * boxSize + marginBoard}
                />,
              ]
            )

          }, []) as any
        }

      </svg>

      <div className={classNames(
        'my-4 sm:my-10 flex items-center justify-center border-opacity-40 transition-all',
        {
          'grayscale': selected[0] === undefined || selected[1] === undefined,
        }
      )}>
        {
          Array.from({ length: board.length }).map((_, index) => (
            <button
              key={`number-keyboard-${index + 1}`}
              className='h-10 px-2 transition-all border-r-2 cursor-pointer sm:h-14 md:px-4 md:text-2xl border-y-2 first-of-type:border-l-2 border-disabled-darker group hover:bg-disabled active:scale-95 disabled:cursor-not-allowed'
              onClick={() => {
                onChange(index + 1)
              }}
              disabled={selected[0] === undefined || selected[1] === undefined}
            >
              {index + 1}
            </button>
          ))
        }
        <button
          className='h-10 px-2 transition-all border-r-2 cursor-pointer sm:h-14 md:px-4 md:text-2xl border-y-2 first-of-type:border-l-2 border-disabled-darker group hover:bg-disabled-darker active:scale-95 text-disabled-darker disabled:cursor-not-allowed'
          onClick={() => {
            onChange(null)
          }}
          disabled={selected[0] === undefined || selected[1] === undefined}
        >
          <svg width='23' height='18' viewBox='0 0 23 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M18.5593 12.7467L18.9207 12.3906L18.5593 12.0344L15.4802 9L18.5593 5.96557L18.9207 5.60944L18.5593 5.25332L17.208 3.92165L16.8571 3.57578L16.5061 3.92165L13.4167 6.96633L10.3272 3.92165L9.97625 3.57578L9.62529 3.92165L8.27404 5.25332L7.91267 5.60944L8.27404 5.96557L11.3531 9L8.27404 12.0344L7.91267 12.3906L8.27404 12.7467L9.62529 14.0783L9.97625 14.4242L10.3272 14.0783L13.4167 11.0337L16.5061 14.0783L16.8571 14.4242L17.208 14.0783L18.5593 12.7467ZM6.70833 1H21.0833C21.8683 1 22.5 1.633 22.5 2.38889V15.6111C22.5 16.367 21.8683 17 21.0833 17H6.70833C6.24163 17 5.86707 16.7677 5.59742 16.3774L0.603658 8.99983L5.59748 1.61311C5.86395 1.22744 6.23681 1 6.70833 1Z' fill='#656565' stroke='#656565'/>
          </svg>
        </button>
      </div>

      <div
        className='flex justify-center gap-2 max-sm:flex-col'
      >
        <Button
          secondary={!withPen}
          className='whitespace-nowrap'
          onClick={() => setWithPen(true)}
        >
          {t('sudoku.withPen')}
        </Button>
        <Button
          secondary={withPen}
          className='whitespace-nowrap'
          onClick={() => setWithPen(false)}
        >
          {t('sudoku.withPencil')}
        </Button>
        <Button
          onClick={() => setValidating(true)}
        >
          {t('sudoku.validate')}
        </Button>

      </div>
    </>
  )
}


function Sudoku() {
  const { t } = useTranslation()
  const { currentGame } = useCurrentGameStore()
  return (
    <Container className='max-w-sm'>
      <MetaHeaders
        title={currentGame?.config?.title || 'Sudoku'}
        description={currentGame?.config?.description || t('sudoku.description')}
        image='/assets/share/sudoku-card.png'
      />

      <InitGameModal>
        <p dangerouslySetInnerHTML={{
          __html: t('sudoku.howToPlay.smallContent')
        }} />
      </InitGameModal>

      <HowPlayModal>
        <div className='flex items-center space-x-5'>
          <div className='w-3/5 prose-base' dangerouslySetInnerHTML={{
            __html: t('sudoku.howToPlay.fullContent')
          }} />
          <div className='w-2/5'>
            <img src={GameExplainSvg} alt='Sudoku Explicación' />
          </div>
        </div>
      </HowPlayModal>

      <FinishGameModal />

      <Board />

    </Container>
  )
}

export default Sudoku
