import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import Container from '@/layout/container'
import Link from '@/components/Link'
import { useTimer } from '@/store/timer'
import { StatusEnum, useCurrentGameStore } from '@/store/game'


type Props = {}

function GameHeader({}: Props) {
  const { time, isActive } = useTimer()
  const { play, pause, setStatus, status, meta } = useCurrentGameStore()
  const { t } = useTranslation()
  return (
    <Container
      className='flex items-center justify-between py-2 sm:py-5'
    >
      <div className='flex-1 flex-grow'>
        <div className='text-xs text-disabled'>
          {t('games.time.spent')}
        </div>
        <div className='flex items-center space-x-2'>
          <div className='w-12 font-bold sm:w-32 sm:text-4xl text-primary'>{DateTime.fromSeconds(time).toFormat('mm’ss’’')}</div>
          <Link
            as='button'
            className='p-2 transform max-sm:scale-50'
            onClick={() => isActive ? pause() : play()}
            disabled={status !== StatusEnum.playing && status !== StatusEnum.paused}
          >
            {
              isActive ?
              <svg width='13' height='24' viewBox='0 0 13 24' className='fill-current' xmlns='http://www.w3.org/2000/svg'>
                <path d='M3 3.22457e-06C3.55228 3.86119e-06 4 0.447719 4 1L4 23C4 23.5523 3.55228 24 3 24L1 24C0.447715 24 3.05674e-08 23.5523 4.86417e-08 23L7.68626e-07 1C7.86701e-07 0.447716 0.447717 2.8255e-07 1 9.19171e-07L3 3.22457e-06Z' fill='#0076A8'/>
                <path d='M12 3.22665e-06C12.5523 3.86212e-06 13 0.447719 13 1L13 23C13 23.5523 12.5523 24 12 24L10 24C9.44772 24 9 23.5523 9 23L9 1C9 0.447716 9.44772 2.8991e-07 10 9.25386e-07L12 3.22665e-06Z' fill='#0076A8'/>
              </svg> :
              <svg width='23' height='26' viewBox='0 0 23 26' className='fill-current' xmlns='http://www.w3.org/2000/svg'>
                <path d='M21.5 12.1339C22.1667 12.5188 22.1667 13.481 21.5 13.8659L2 25.1243C1.33333 25.5092 0.499999 25.028 0.499999 24.2582L0.5 1.74157C0.5 0.971769 1.33333 0.490646 2 0.875546L21.5 12.1339Z' fill='#0076A8'/>
              </svg>
            }
          </Link>
        </div>
      </div>
      <div className='flex-1 flex-grow text-right max-sm:hidden'>
        <Link
          as='button'
          onClick={() => setStatus(StatusEnum.howToPlay)}
        >
          {t('games.howToPlay')}
        </Link>
      </div>
      {/* <div className='flex-1 flex-grow text-right'>
        <div className='text-xs text-disabled'>Punts</div>
        <div className='font-bold sm:text-4xl text-primary'>120</div>
      </div> */}
    </Container>
  )
}

export default GameHeader
