import { createPortal } from 'react-dom'
import classNames from 'classnames'


type Props = {
  children: React.ReactNode
  onClose: () => void
  show: boolean
  className?: string
}


function Modal({
  children,
  onClose,
  show,
  className = '',
}: Props) {
  if (document.querySelector('#ara-game-container') === null) return null
  if (!show) return null
  return createPortal(
    <div onClick={onClose} className='absolute top-0 bottom-0 left-0 right-0 z-50 flex items-start justify-center'>
      <div className='absolute top-0 bottom-0 left-0 right-0 z-10 bg-gradient-to-b from-disabled-dark to-black opacity-80' />
      <div
        className={classNames(
          'flex flex-col bg-white rounded-lg overflow-hidden z-20 w-full max-w-5xl',
          'sticky sm:top-10 m-4 sm:m-5',
          className,
        )}
        onClick={
          (e) => e.stopPropagation()
        }
      >
        {children}
      </div>
    </div>,
    document.querySelector('#ara-game-container') as HTMLElement,
  )
}

export default Modal
