import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router'


type Props = {
  title: string
  description: string
  image: string
}


function MetaHeaders({
  title,
  description,
  image,
}: Props) {

  const location = useLocation()

  let _image = image
  if (image.startsWith('/')) {
    _image = import.meta.env.VITE_BASENAME + image
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={_image} />
      <meta property='og:url' content={import.meta.env.VITE_BASENAME + location.pathname} />
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content={title} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={_image} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content={title} />
      <meta name='twitter:creator' content={title} />

      <link rel='canonical' href={import.meta.env.VITE_BASENAME + location.pathname + '/'} />

    </Helmet>
  )
}

export default MetaHeaders
