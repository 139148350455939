import { useEffect, useState } from 'react'
import { AiOutlineReload } from 'react-icons/ai'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Container from '@/layout/container'
import Button from '@/components/button'
import Link from '@/components/Link'
import { FinishGameModal, HowPlayModal, InitGameModal } from '@/components/games/modal'
import MetaHeaders from '@/components/games/meta-headers'
import { StatusEnum, useCurrentGameStore } from '@/store/game'

export const Letters = ({central, outside, onClick}: {central: string, outside: string[], onClick: (letter: string) => void}) => {
  return (
    <svg height='100%' className='max-h-[600px] uppercase' strokeMiterlimit='10' version='1.1' viewBox='0 0 403.472 385.319' width='100%' xmlSpace='preserve' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <g onClick={() => onClick(central)} className='group cursor-pointer active:scale-[.94] transform transition-all' style={{
        transformOrigin: 'center center',
        transformBox: 'fill-box',

      }}>
        <path d='M201.736 260.523L142.965 226.591L142.965 158.728L201.736 124.797L260.507 158.728L260.507 226.591L201.736 260.523Z' className='transition-all fill-current text-primary group-hover:text-primary-hover group-active:text-primary-active' fill='#00bfff' fillRule='nonzero' opacity='1' stroke='none'/>
        <text startOffset='50%' dy='16' x='50%' y='50%' textAnchor='middle' className='text-5xl font-medium text-white transition-all fill-current'>{central}</text>
      </g>
      <g onClick={() => onClick(outside[0])} className='group cursor-pointer active:scale-[.94] transform transition-all' style={{
        transformOrigin: 'center center',
        transformBox: 'fill-box',

      }}>
        <path d='M325.417 260.523L266.646 226.591L266.646 158.728L325.417 124.797L384.188 158.728L384.188 226.591L325.417 260.523Z' className='transition-all fill-current text-primary-light group-hover:text-primary-light-hover group-active:text-primary-light-active' fill='#00bfff' fillRule='nonzero' opacity='1' stroke='none'/>
        <text startOffset='50%' dy='16' x='80.5%' y='50%' textAnchor='middle' className='text-5xl font-medium transition-all fill-current text-primary group-active:text-white'>{outside[0]}</text>
      </g>
      <g onClick={() => onClick(outside[1])} className='group cursor-pointer active:scale-[.94] transform transition-all' style={{
        transformOrigin: 'center center',
        transformBox: 'fill-box',

      }}>
        <path d='M78.0551 260.523L19.2841 226.591L19.2841 158.728L78.0551 124.797L136.826 158.728L136.826 226.591L78.0551 260.523Z' className='transition-all fill-current text-primary-light group-hover:text-primary-light-hover group-active:text-primary-light-active' fill='#00bfff' fillRule='nonzero' opacity='1' stroke='none'/>
        <text startOffset='50%' dy='16' x='19.5%' y='50%' textAnchor='middle' className='text-5xl font-medium transition-all fill-current text-primary group-active:text-white'>{outside[1]}</text>
      </g>
      <g onClick={() => onClick(outside[2])} className='group cursor-pointer active:scale-[.94] transform transition-all' style={{
        transformOrigin: 'center center',
        transformBox: 'fill-box',

      }}>
        <path d='M263.841 368.439L205.07 334.508L205.07 266.645L263.841 232.713L322.612 266.645L322.612 334.508L263.841 368.439Z' className='transition-all fill-current text-primary-light group-hover:text-primary-light-hover group-active:text-primary-light-active' fill='#00bfff' fillRule='nonzero' opacity='1' stroke='none'/>
        <text startOffset='50%' dy='16' x='65.3%' y='78%' textAnchor='middle' className='text-5xl font-medium transition-all fill-current text-primary group-active:text-white'>{outside[2]}</text>
      </g>
      <g onClick={() => onClick(outside[3])} className='group cursor-pointer active:scale-[.94] transform transition-all' style={{
        transformOrigin: 'center center',
        transformBox: 'fill-box',
      }}>
        <path d='M139.823 368.439L81.052 334.508L81.052 266.645L139.823 232.713L198.594 266.645L198.594 334.508L139.823 368.439Z' className='transition-all fill-current text-primary-light group-hover:text-primary-light-hover group-active:text-primary-light-active' fill='#00bfff' fillRule='nonzero' opacity='1' stroke='none'/>
        <text startOffset='50%' dy='16' x='140' y='78%' textAnchor='middle' className='text-5xl font-medium transition-all fill-current text-primary group-active:text-white'>{outside[3]}</text>
      </g>
      <g onClick={() => onClick(outside[4])} className='group cursor-pointer active:scale-[.94] transform transition-all' style={{
        transformOrigin: 'center center',
        transformBox: 'fill-box',

      }}>
        <path d='M263.604 152.606L204.833 118.675L204.833 50.8119L263.604 16.8805L322.375 50.8119L322.375 118.675L263.604 152.606Z' className='transition-all fill-current text-primary-light group-hover:text-primary-light-hover group-active:text-primary-light-active' fill='#00bfff' fillRule='nonzero' opacity='1' stroke='none'/>
        <text startOffset='50%' dy='16' x='65.3%' y='22%' textAnchor='middle' className='text-5xl font-medium transition-all fill-current text-primary group-active:text-white'>{outside[4]}</text>
      </g>
      <g onClick={() => onClick(outside[5])} className='group cursor-pointer active:scale-[.94] transform transition-all' style={{
        transformOrigin: 'center center',
        transformBox: 'fill-box',

      }}>
        <path d='M139.823 152.606L81.052 118.675L81.052 50.8119L139.823 16.8805L198.594 50.8119L198.594 118.675L139.823 152.606Z' className='transition-all fill-current text-primary-light group-hover:text-primary-light-hover group-active:text-primary-light-active' fill='#00bfff' fillRule='nonzero' opacity='1' stroke='none'/>
        <text startOffset='50%' dy='16' x='140' y='22%' textAnchor='middle' className='text-5xl font-medium transition-all fill-current text-primary group-active:text-white'>{outside[5]}</text>
      </g>
    </svg>
  )
}

type ConfigProps = {
  word: string
  wordLabel: string
  description: string
}


type Props = {}

function HiddenWords({}: Props) {
  const {
    currentGame,
    currentGameState,
    setCurrentGameState,
    finish,
    status,
  } = useCurrentGameStore()
  const { t } = useTranslation()

  const responses: string[] = currentGameState?.state || []

  const [letters, setLetters] = useState<string[]>([])
  const [centralLetter, setCentralLetter] = useState<string>('')
  const [words, setWords] = useState<ConfigProps[]>([])
  const [response, setResponse] = useState('')
  const [responseStatus, setResponseStatus] = useState<'error' | 'ok' | null>(null)

  const currentGameId = currentGame?.id

  useEffect(() => {
    const _words = currentGame?.config?.words?.map((config: ConfigProps) => ({
      word: config.word.toUpperCase(),
      wordLabel: config.wordLabel.toUpperCase(),
      description: config.description,
    }))
    const _letters = currentGame?.config?.letters?.map((letter: string) => letter.toUpperCase())
    const _fixedLetter = currentGame?.config?.fixedLetter?.toUpperCase()

    setLetters(shuffle(_letters))
    setCentralLetter(_fixedLetter)
    setWords(_words)
    setResponse('')
    setResponseStatus(null)
  }, [currentGameId])

  useEffect(() => {
    const keyUp = (event: KeyboardEvent) => {
      if (status !== 'playing') return
      if (responseStatus !== null) return

      if (event.key === 'Enter') {
        validateResponse()
      }

      if (event.key === 'Backspace') {
        setResponse(response.slice(0, -1))
      }

      if (
        letters.includes(event.key.toUpperCase()) ||
        event.key.toUpperCase() === centralLetter
      ) {
        setResponse(response + event.key.toUpperCase())
      }

      if (event.key === 'Escape') {
        setResponse('')
      }
    }

    window.addEventListener('keyup', keyUp)

    return () => {
      window.removeEventListener('keyup', keyUp)
    }

  }, [response, letters, status, responseStatus, centralLetter])

  function shuffle(array: string[] | undefined): string[] {
    if (!array) return []

    const newArray = [...array]
    let currentIndex = array.length,
      temporaryValue,
      randomIndex

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      temporaryValue = newArray[currentIndex]
      newArray[currentIndex] = newArray[randomIndex]
      newArray[randomIndex] = temporaryValue.toUpperCase()
    }

    return newArray
  }

  function showMessage(message: string, error = false, timeout = 1000) {
    setResponseStatus(error ? 'error' : 'ok')
      setResponse(message)
      setTimeout(() => {
        setResponseStatus(null)
      }, timeout)
      setTimeout(() => {
        setResponse('')
      }, 100 + timeout)
  }

  function validateResponse() {
    if (response.trim().length === 0) return

    const currentResponseWord: ConfigProps | undefined = words.find((w: ConfigProps) => w.word === response)

    if (currentResponseWord && responses.includes(currentResponseWord.wordLabel)) {
      showMessage('PARAULA JA TROBADA', true)

    } else if (!response.includes(centralLetter)) {
      showMessage('FALTA LA LLETRA DEL MIG', true)

    } else if (response.length < 3) {
      showMessage('MÍNIN 3 LLETRES', true)

    } else if (currentResponseWord) {
      showMessage('+1')
      setCurrentGameState([
        ...responses,
        currentResponseWord.wordLabel,
      ])

      if (responses.length + 1 === words.length) {
        showMessage('HAS GUANYAT', false, 8000)
        finish()
      }

    } else {
      showMessage(response, true)

    }
  }

  function clearLetter() {
    setResponse(response.slice(0, -1))
  }

  function onClick(letter: string) {
    if (status !== 'playing') return
    if (responseStatus !== null) return
    setResponse(response.toUpperCase() + letter.toUpperCase())
  }

  return (
    <Container className='max-w-sm mb-40 select-none'>
      <MetaHeaders
        title={currentGame?.config?.title || 'Amagamots'}
        description={currentGame?.config?.description || t('hidden_words.description')}
        image='/assets/share/hidden_word-card.png'
      />
      <FinishGameModal />
      <HowPlayModal>
        <div className='flex items-center space-x-5'>
          <div className='w-3/5 prose-base' dangerouslySetInnerHTML={{
            __html: t('hiddenWords.howToPlay.fullContent')
          }} />
          <div className='w-2/5'>
            <Letters central='A' outside={['B', 'C', 'D', 'E', 'F', 'G']} onClick={() => {}} />
          </div>
        </div>
      </HowPlayModal>

      <InitGameModal>
        <p
          dangerouslySetInnerHTML={{
            __html: t('hiddenWords.howToPlay.smallContent'),
          }}
        />
      </InitGameModal>

      <div className='flex items-center justify-center text-2xl sm:mb-10'>

       {
        !responseStatus && status === StatusEnum.playing ?
          <>
            <div className='uppercase transition-all sm:py-2'>{response}</div>
            <div className='w-1 h-8 transition-all sm:h-12 bg-primary animate-pulse' />
          </> :
          <div className={classNames(
            'transition-all px-2 sm:px-4 sm:py-2 rounded-sm uppercase',
            {
              'bg-danger text-white': !!response && responseStatus === 'error',
              'bg-primary text-white': !!response && responseStatus === 'ok',
            }
          )}>{response}</div>
       }
      </div>
      <div className='max-w-[300px] w-full mx-auto'>
        <Letters central={centralLetter} outside={letters} onClick={onClick} />
      </div>

      <div className='flex items-stretch justify-center mt-5 space-x-2 text-center sm:mt-10 sm:space-x-4'>
        <Button
          secondary
          disabled={
            response.trim().length === 0 ||
            status !== StatusEnum.playing ||
            responseStatus !== null
          }
          onClick={clearLetter}
        >
          {t('hiddenWords.clear')}
        </Button>

        <Button
          secondary
          className='!px-4 items-center justify-center flex max-sm:hidden'
          onClick={
            () => setLetters(shuffle(currentGame?.config?.letters))
          }
          disabled={status !== StatusEnum.playing || responseStatus !== null}
        >
          <AiOutlineReload />
        </Button>

        <Button
          disabled={
            response.trim().length === 0 ||
            status !== StatusEnum.playing ||
            responseStatus !== null
          }
          onClick={validateResponse}
        >
          {t('hiddenWords.validate')}
        </Button>
      </div>

      <div
        className='flex items-center justify-center mt-10 text-center divide-x-2 divide-disabled-light'
      >
        <span
          className='mr-3 text-primary'
        >
          {t('hiddenWords.wordsFound')} {words?.length - responses.length}
        </span>
        {/* <Link
          as='button'
          onClick={finish}
          className='pl-3'
          disabled={status !== StatusEnum.playing || responseStatus !== null}
        >
          {t('hiddenWords.finish')}
        </Link> */}
      </div>
      <div className='flex flex-wrap items-center justify-center max-w-2xl mx-auto mt-10 space-x-3 text-center capitalize'>
        {
          [...responses].sort().map((response, index) => (
            <div key={index}>
              <div className='text-2xl'>
                {response.toLowerCase()}
                {
                  responses?.[index + 1] && ','
                }
              </div>
            </div>
          ))
        }
      </div>
    </Container>
  )
}

export default HiddenWords
