import { Navigate, createBrowserRouter } from 'react-router-dom'

import { HiddenWords, Sudoku } from '@/games'
import GameContainer from '@/components/games/container'
import Home from '@/pages/Home'
import Container from '@/layout/container'
import MetaHeaders from '@/components/games/meta-headers'


const router = createBrowserRouter([
  {
    path: '/',
    children: [
      {
        path: 'amagamots/:id?',
        element: <GameContainer type='hidden_words' url='/amagamots'><HiddenWords /></GameContainer>,
      },
      {
        path: 'hidden_words/:id?',
        element: <Navigate replace to='/amagamots' />,
      },
      {
        path: 'sudokus/:id?',
        element: <GameContainer type='sudoku' url='/sudokus'><Sudoku /></GameContainer>,
      },
      {
        path: '/sudoku/:id?',
        element: <Navigate replace to='/sudokus' />,
      },
      {
        path: '/',
        element: <Home />,
      },
    ],
  },
  {
    path: '*',
    element: <Container>
      <MetaHeaders
        title='404 Not Found'
        description='Pàgina no trobada'
        image='https://jocs.ara.cat/ara.png'
      />
      404 Not Found
    </Container>,
  },
])

export default router
