import { DateTime } from 'luxon'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

import Container from '@/layout/container'
import { useCurrentGameStore } from '@/store/game'
import { Link } from 'react-router-dom'
import { useGamesStore } from '@/store/games'


export default function Historical() {
  const { currentGame, meta, url, type } = useCurrentGameStore()
  const { games } = useGamesStore()
  if (!currentGame) {
    return <Container className='relative flex items-stretch justify-between h-20 border-b border-disabled-light' />

  }

  return (
    <Container className='relative flex items-stretch justify-between h-10 border-b border-gray-300 sm:h-14'>
      {
        meta?.previousGame ?
        <Link to={`${url}/${meta.previousGame}`} className='flex items-center sm:text-3xl'>
          <IoIosArrowBack />
          <span className='text-base text-gray-400 max-sm:hidden'>{DateTime.fromISO(meta?.previousGameLabel).toFormat('dd LLLL yyyy')}</span>
        </Link> : <div className='flex-grow' />
      }
      <div className='absolute text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 sm:text-xl left-1/2'>
        {DateTime.fromISO(currentGame?.publicationDate).toFormat('dd LLLL yyyy')}
      </div>
      {
        meta?.nextGame ?
        <Link to={`${url}/${meta.nextGame}`} className='flex items-center sm:text-3xl'>
          <span className='text-base text-gray-400 max-sm:hidden'>{DateTime.fromISO(meta?.nextGameLabel).toFormat('dd LLLL yyyy')}</span>
          <IoIosArrowForward />
        </Link> : <div className='flex-grow' />
      }
    </Container>
  )
}
