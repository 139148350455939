import classNames from 'classnames'


type Props = {
  children?: React.ReactNode
  as?: React.ElementType
  className?: string
}

function Container({
  children,
  as: Component = 'div',
  className = '',
}: Props) {
  return (
    <Component
      className={classNames(
        'max-w-[972px] mx-auto px-3',
        className,
      )}
    >
      {children}
    </Component>
  )
}

export default Container
