import { ScrollRestoration } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { GameConfiguration, useGamesStore } from '@/store/games'
import ARALogo from '@/assets/ara.png'
import WordleCat from '@/assets/wordlecat.svg'
import Mots from '@/assets/motsencreuats.svg'
import Link from '@/components/Link'
import MetaHeaders from '@/components/games/meta-headers'

export const WordleLogo = () => (
	<div className='flex items-center space-x-px'>
		<span>Trobamot</span>
	</div>
)

export default function Home() {
	const gamesStore = useGamesStore()
	const { t } = useTranslation()

	const gamesConfigurations: any =
		gamesStore?.games?.map((game: GameConfiguration) => ({
			title: game.config.title,
			order: game.config.order,
			description: game.config.description,
			image: game.config.image,
			link: game.gameType,
		})) ?? []

	const games = [
		{
			title: 'Trobamot',
			description:
				'Endevina la paraula del dia de 5 lletres en un màxim de 6 intents',
			order: 2,
			Title: <WordleLogo />,
			image: WordleCat,
			rel: 'noreferer',
			link: 'https://jocs.ara.cat/trobamot/',
		},
		{
			title: 'Mots Encreuats',
			description:
				'Mantingues la ment activa amb els mots encreuats del Senyor Ventura',
			order: 0,
			image: Mots,
			rel: 'noreferer',
			link: 'https://jocs.ara.cat/motsencreuats/',
		},
		...gamesConfigurations,
	].sort((a, b) => a.order - b.order)
	return (
		<>
			<div className='flex flex-col space-y-5 sm:space-y-12'>
				<MetaHeaders
					title='Jocs'
					description='Activa el català amb els jocs de l’ARA'
					image={ARALogo}
				/>
				<div className='flex items-stretch justify-center h-16 px-3 space-x-3 border-b border-gray-300'>
					<img src={ARALogo} alt='ARA Logo' className='max-h-full py-3' />
					<div className='my-3 border-r border-gray-300' />
					<h1 className='flex items-center text-lg font-semibold uppercase md:flex-grow-0'>
						JOCS
					</h1>
				</div>
				<div className='px-5 text-2xl font-medium text-center sm:text-4xl'>
					{t('home.title')}
				</div>
				<div className='grid flex-wrap grid-cols-1 gap-5 px-4 m-auto !mb-10 sm:gap-10 sm:p-10 sm:grid-cols-2 sm:flex-row sm:space-y-0 sm:mb-20'>
					{games.map(({ title, image, Title, link, description }) => {
						const ComponentLink = link.startsWith('http') ? 'a' : Link
						return (
							<ComponentLink
								className='
                  flex flex-col items-center justify-center
                  w-full max-w-md
                  p-4 sm:p-5 sm:space-y-5
                  border border-primary-light-active bg-primary-light
                  rounded-lg
                  !no-underline
                '
								href={link}
								to={link}
								key={title}
								rel='noreferer'
							>
								<img
									src={image}
									alt={title}
									className='flex-grow object-contain w-full h-full'
								/>
								<h2 className='text-2xl text-center text-black md:text-4xl max-sm:!mt-0 font-medium'>
									{Title ?? title}
								</h2>
								<p className='text-center text-black'>{description}</p>
								<span className='flex items-center mt-4 px-10 py-3 !mb-2 font-semibold text-white transition-all rounded-sm sm:py-4 max-md:text-sm bg-primary group-hover:text-primary-light'>
									{t('games.play')}
								</span>
							</ComponentLink>
						)
					})}
				</div>
				<ScrollRestoration />
			</div>

			{/* Logo col·laboracions */}
			<div className='text-center text-[#999a9b] mt-5'>Col·laboradors:</div>
			<div className='flex items-stretch justify-center h-16 bg-black mt-[10px]'>
				<img
					src='https://s1static.ara.cat/images/generalitat-pel-catala.png'
					alt='Logo Generalitat'
					className='max-h-full py-3'
					height='57'
					width='319'
				/>
			</div>
		</>
	)
}
