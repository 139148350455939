import { create } from 'zustand'

import { client } from '@/api'


export interface GameConfiguration {
  id: string
  config: {
    title: string
    description: string
    order: number
    icon: string
    image: string
    path: string
    share: {
      title: string
      description: string
      image: string
    }
  }
  gameType: string
}


export interface GameDto {
  games: GameConfiguration[]
  fetchGames: () => Promise<void>
}

export const useGamesStore = create <GameDto> (
  (set: any, get: any) => ({
    games: [],
    fetchGames: async () => {
      const { data: games, meta }: any = await client.fetch(
        [ 'games', ],
      )
      if (! games) {
        return
      }

      set({
        games,
        meta,
      })
    },
  }),
)
