import classNames from 'classnames'

type Props = {
  onClick?: () => void
  className?: string
  as?: React.ElementType
  children: React.ReactNode
  secondary?: boolean
  disabled?: boolean
  [key: string]: any
}

function Button({
  onClick = () => {},
  className = '',
  as: Component = 'button',
  secondary = false,
  disabled = false,
  children,
  ...props
}: Props) {
  return (
    <Component
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        'px-5 sm:px-10 py-2 sm:py-4 font-semibold rounded-sm',
        {
          'bg-primary text-white hover:bg-primary-hover active:text-primary active:bg-primary-active': !secondary && !disabled,
          'bg-primary-light text-primary hover:bg-primary-light-hover active:bg-primary-light-active active:text-white': secondary && !disabled,
          'cursor-not-allowed text-disabled bg-disabled-light': disabled,
          'active:scale-[.95]': !disabled,
        },
        'transition-all',
        className,
      )}
      {...props}
    >
      {children}
    </Component>
  )
}

export default Button
