import React from 'react'
import { RouterProvider } from 'react-router'
import ReactDOM from 'react-dom/client'
import { Settings } from 'luxon'
import { ApiProvider } from 'jsonapi-react'
import { DidomiSDK } from '@didomi/react'

import router from '@/routes'
import { client } from '@/api'
import Auth from '@/components/auth'
import '@/index.scss'
import '@/i18n'
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'GTM-M63PMSS'
}
TagManager.initialize(tagManagerArgs)

Settings.defaultLocale = 'ca-ES'

const rootElement = document.getElementById('root') as HTMLElement

if (rootElement?.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    rootElement,
    <React.StrictMode>
      <ApiProvider client={client}>
        <Auth>
          <RouterProvider router={router} />
        </Auth>
      </ApiProvider>
    </React.StrictMode>,
	)
} else {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <React.StrictMode>
      <DidomiSDK
        apiKey='d20cb746-9cdd-461f-a57b-cd41f54fad65'
        iabVersion={2}
        gdprAppliesGlobally={true}
        sdkPath='https://sdk.privacy-center.org/'
        embedTCFStub={true}
      />
      <ApiProvider client={client}>
        <Auth>
          <RouterProvider router={router} />
        </Auth>
      </ApiProvider>
    </React.StrictMode>,
  )
}
